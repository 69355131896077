import parseHtmlString, { DOMNode, domToReact } from "html-react-parser";
import { HtmlTag } from "../../../interfaces/variousInterfaces";
import { HtmlToComponentMapping } from "../../general/parseWpHtmlToReact/htmlParsingInterfaces";
import { nodeIsTag } from "../../general/parseWpHtmlToReact/nodeIsTag";
import { sanitizeHtml } from "../../general/sanitizeHtml";
import { replaceEmojis } from "../replaceEmojis";

/**
 * Parse an html string to react, replacing certain elements with custom function components.
 *
 * Note that this will also sanitize the given html.
 *
 * @param html the raw html string
 *
 * @param mapFromTagsToComponents a mapping from html tag names (div, p …) to components that will replace them.
 *
 * @param mapFromIdsToComponents a mapping from ids to factories that return function components from a domNode.
 * This takes precedence over the tag mapping.
 */
export const replaceHtmlWithFunctionComponent = (
  html: string | undefined,
  mapping: HtmlToComponentMapping
): string | JSX.Element | JSX.Element[] => {
  const emojisParsed = replaceEmojis(html);
  const sanitizedHTML = sanitizeHtml(emojisParsed);

  /*
   * This replace function is used for the top-level call to the html-react-parser,
   *  and also for recursive calls to handle nested element structures.
   */
  function replaceDomNode(domNode: DOMNode) {
    if (!nodeIsTag(domNode)) {
      return null;
    }

    const classNames = domNode.attribs.class?.split(" ") || [];

    const id = domNode.attribs.id;
    /*
     * html-react-parser sets domNode.name as string,
     *  but we can be sure it's an HtmlTag.
     */
    const tagName = domNode.name as HtmlTag;

    /*
     * Check if one of the element classes matches a component in the map.
     * Warning: if multiple classes match, only the first one is returned.
     */
    const matchedClassName = classNames.find(
      (className) => !!mapping.classNameMap?.[className]
    );

    const ComponentFromId = mapping.idMap?.[id];
    const ComponentFromClass = matchedClassName
      ? mapping.classNameMap?.[matchedClassName]
      : null;
    const ComponentFromTag = mapping.tagMap?.[tagName];

    const Component = ComponentFromId || ComponentFromClass || ComponentFromTag;

    return Component ? (
      <Component replacedElement={domNode}>
        {domToReact(domNode.children, {
          replace: replaceDomNode,
        })}
      </Component>
    ) : null;
  }

  return parseHtmlString(sanitizedHTML, {
    replace: replaceDomNode,
  });
};
