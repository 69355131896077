import twemoji from "twemoji";

const twemojiDefaults: Partial<twemoji.ParseObject> = {
  base: "/",
  folder: "svg",
  ext: ".svg",
};

export const replaceEmojis = (html: string | undefined): string => {
  // The named import causes a weird webpack error 🤷‍♂️
  // eslint-disable-next-line import/no-named-as-default-member
  return twemoji.parse(html || "", twemojiDefaults);
};
