/**
 * This function originally is delivered from gatsby for wordpress images.
 * It has been adjusted manually because of ios not loading images.
 */
export const performGatsbyImageMagic = (): void => {
  /* eslint-disable */
  const t = "undefined" != typeof HTMLImageElement;
  if (t) {
    const elements = document.querySelectorAll("img[data-main-image]");
    for (const element of elements) {
      const e = element as HTMLImageElement;
      e.dataset.src &&
        (e.setAttribute("src", e.dataset.src), e.removeAttribute("data-src")),
        e.dataset.srcset &&
          (e.setAttribute("srcset", e.dataset.srcset),
          e.removeAttribute("data-srcset"));
      const t = e.parentNode?.querySelectorAll("source[data-srcset]");
      if (!t) return;
      for (const element of t) {
        const e = element as HTMLImageElement;
        e.setAttribute("srcset", e.dataset.srcset || ""),
          e.removeAttribute("data-srcset");
      }
      e.complete && (e.style.opacity = "1");
    }
    /* eslint-enable */
  }
};
