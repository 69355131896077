import DOMPurify from "isomorphic-dompurify";

const defaultOptions: DOMPurify.Config = {
  ADD_TAGS: ["iframe"],
  USE_PROFILES: {
    html: true,
  },
};

export const sanitizeHtml = (
  html: string,
  sanitizeOptions?: DOMPurify.Config
): string => {
  return DOMPurify.sanitize(html, {
    ...defaultOptions,
    ...sanitizeOptions,
  }).toString();
};
